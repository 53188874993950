.cam {
  margin: 0.4rem auto 0.4rem;
  background: cadetblue;
  border-radius: 1rem;
  position: relative;
  overflow: hidden;
  height: 17vh;
  width: 18vw;
  transition-duration: 0.2s;

  @media screen and (min-width: 480px) and (max-width: 768px) and (orientation: portrait) {
    max-width: 32rem;
    height: 18rem;
    width: 100%;
  }

  /* iPads (portrait) ----------- */
  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    max-width: 32rem;
    height: 18rem;
    width: 100%;
  }

  /* iPads (landscape) ----------- */
  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    max-width: 27rem;
    height: 13rem;
    width: 100%;
  }

  @media screen and (max-width: 480px) {
    max-width: 27rem;
    height: 13rem;
    width: 100%;
  }

  @media screen and (max-width: 480px) and (max-height: 792px) {
    max-width: 20rem;
    height: 10rem;
    width: 100%;
  }

  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .cam-controls {
    margin-right: 1rem;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    & > div {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
    }

    .item {
      width: 4.5rem;
      height: 4.5rem;
      margin-bottom: 0.3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #313131;
      border-radius: 50%;
      border: 1px solid #707070;
      z-index: 2;

      // extra properties
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
      cursor: pointer;

      &:not(.default) {
        background: #cf5060;
        border: none;
      }

      svg {
        fill: #fff;
        stroke: #fff;
        color: #fff;
        font-size: 2rem;
        @media screen and (max-width: 480px) {
          font-size: 1rem;
        }
      }

      @media screen and (max-width: 480px) {
        width: 3rem;
        height: 3rem;
        .video-waves {
          height: 2.5rem;
          width: 2.5rem;
        }
      }
    }
  }
}
