.control-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6rem;
  overflow: hidden;
  .content {
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-around;

    .item {
      cursor: pointer;
      width: 4rem;
      height: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #313131;
      border-radius: 50%;
      border: 1px solid #707070;
      z-index: 2;

      &:not(.default) {
        background: #cf5060;
        border: none;
      }

      svg {
        fill: #fff;
        stroke: #fff;
        color: #fff;
        font-size: 1.5rem;
      }
    }
  }

  &::after {
    content: "";
    left: 0;
    top: 0;
    position: absolute;
    background-color: rgba($color: #000000, $alpha: 0.35);
    width: 200%;
    height: 200%;
    z-index: 1;
  }
}
