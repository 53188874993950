.game {
  //width: 100%;
  height: 100%;
  padding: 1rem 1rem 1rem 8vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  //  subtract the navbar space
  @media screen and (max-width: 480px) and (orientation: portrait) {
    padding: 0.5rem 2rem;
    width: 100%;
    height: calc(100% - 6rem);
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    width: 100%;
    height: calc(100% - 6rem);
  }

  @media (min-width: 801px) {
    @supports (-webkit-touch-callout: none) {
      width: 100%;
      height: calc(100%);
    }
  }

  @media (min-width: 801px) {
    @supports not (-webkit-touch-callout: none) {
      width: 100%;
      height: calc(100%);
    }
  }

  @media (device-width: 820px) and (device-height: 1180px) {
    @supports not (-webkit-touch-callout: none) {
      width: 100%;
      height: calc(100% - 12rem);
    }
  }

  @media only screen and (orientation: portrait) {
    width: 100%;
    height: calc(100% - 6rem);
  }

  @media screen and (min-width: 1281px) and (orientation: landscape) {
    width: 100%;
    height: calc(100%);
  }

  .control-room {
    width: 100%;

    .room-duration {
      text-align: center;
      margin-bottom: 1rem;

      span {
        font-weight: 900;
        font-size: 2rem;
      }

      * {
        line-height: 1;
        color: #fae8cc !important;
      }

      @media screen and (min-width: 801px) {
        margin-bottom: 1rem;
        span {
          font-weight: 900;
          font-size: 1.5rem;
        }
        * {
          line-height: 1;
          color: #fae8cc !important;
        }
      }
    }

    .game-actions {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      //margin-top: 2rem;
      //margin-bottom: 2rem;

      @media screen and (max-width: 480px) {
        display: none;
      }

      @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        display: none;
      }

      * {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .item {
        margin-bottom: 1rem;
      }

      @media screen and (min-width: 801px) and (max-width: 1024px) {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }

    .turn-table {
      @media screen and (max-width: 480px) {
        display: none;
      }
    }
  }

  @media screen and (min-width: 801px) {
    .ludo-button {
      width: 100px;
      padding: 6px;
    }
  }
}
