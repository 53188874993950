.hamburger-wrapper {
  height: 3.5rem;
  width: 3.5rem;

  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 2rem;
  z-index: 11;

  @media screen and (max-width: 480px) {
    display: flex;
  }

  /* iPads (portrait) ----------- */
  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    display: flex;
    height: 6rem;
    width: 6rem;
  }
}

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 2.3rem;
  height: 2.3rem;
  border: none;
  cursor: pointer;
  padding: 1px;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    margin-bottom: 1px;
    height: 0.25rem;
    background: white;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    &:first-child {
      transform: rotate(0);
      height: 3px;
      background-color: rgb(236, 151, 0);
    }

    &:nth-child(2) {
      opacity: 1;
      height: 3px;
      transform: translateX(0);
      background-color: rgb(65, 181, 167);
    }

    &:nth-child(3) {
      transform: rotate(0);
      height: 3px;
      background-color: rgb(229, 70, 42)
    }

    &.open:first-child {
      transform: rotate(45deg);
    }

    &.open:nth-child(2) {
      opacity: 0;
      transform: translateX(20px);
    }

    &.open:nth-child(3) {
      transform: rotate(-45deg);
      background-color: rgb(236, 151, 0)
    }
  }
}