$backdropSize: 20rem;
$imageSize: 10rem;

.player-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 480px) {
    display: none;
  }

  & > div:last-of-type {
    width: 100%;
    text-align: center;
    transform: translateY(-30px);

    & > * {
      margin: 0 auto;
    }
  }

  .backdrop {
    width: $backdropSize;
    height: $backdropSize;

    /* Desktops and laptops ----------- */
    @media screen and (min-width: 1224px) {
      width: ($backdropSize * 0.8);
      height: ($backdropSize * 0.8);
    }

    /* (1366x768) WXGA Display */
    @media screen and (max-width: 1366px) {
      width: ($backdropSize * 0.5);
      height: ($backdropSize * 0.5);
    }

    @media (min-width: 801px) {
      width: ($backdropSize * 0.4);
      height: ($backdropSize * 0.4);
    }

    /* iPads (landscape) ----------- */
    @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      width: ($backdropSize * 0.7);
      height: ($backdropSize * 0.7);
    }

    /* iPads (portrait) ----------- */
    @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      width: ($backdropSize * 0.7);
      height: ($backdropSize * 0.7);
    }

    @media screen and (min-width: 1281px) and (orientation: landscape) {
      width: ($backdropSize * 0.8);
      height: ($backdropSize * 0.8);
    }

    @media screen and (device-width: 1366px) and (device-height: 768px) {
      width: ($backdropSize * 0.6);
      height: ($backdropSize * 0.6);
    }

    background: rgba($color: #fff, $alpha: 0.2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 3.5rem;
  }

  img {
    height: $imageSize;
    position: relative;

    /* Desktops and laptops ----------- */
    @media screen and (min-width: 1224px) {
      width: ($imageSize * 0.8);
      height: ($imageSize * 0.8);
    }

    /* (1366x768) WXGA Display */
    @media screen and (max-width: 1366px) {
      width: ($imageSize * 0.5);
      height: ($imageSize * 0.5);
    }

    @media (min-width: 801px) {
      width: ($imageSize * 0.4);
      height: ($imageSize * 0.4);
    }

    /* iPads (landscape) ----------- */
    @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      width: ($imageSize * 0.7);
      height: ($imageSize * 0.7);
    }

    @media screen and (min-width: 480px) and (max-width: 768px) {
      width: ($imageSize * 0.4);
      height: ($imageSize * 0.4);
    }

    /* iPads (portrait) ----------- */
    @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      width: ($imageSize * 0.7);
      height: ($imageSize * 0.7);
    }

    @media screen and (min-width: 1281px) and (orientation: landscape) {
      width: ($imageSize * 0.8);
      height: ($imageSize * 0.8);
    }

    @media screen and (device-width: 1366px) and (device-height: 768px) {
      width: ($imageSize * 0.7);
      height: ($imageSize * 0.7);
    }
  }
}
