.message-container {
  flex: auto;
  background: rgba($color: #fff, $alpha: 0.4);
  width: 100%;
  border-radius: 2rem;
  display: flex;
  padding: 2rem;
  flex-direction: column;

  .message {
    line-height: 1;
    max-width: 50%;
    font-weight: 600;
    width: fit-content;
    margin-bottom: 0.5rem;
    font-size: 1.4rem;

    &.left {
      margin-right: auto;
    }
    &.right {
      margin-left: auto;
    }
  }
}

input.message-box {
  margin-top: 2rem;
  border-radius: 1rem;
  overflow: hidden;
  font-size: 1.6rem;
  width: 100%;
  padding: 1rem 2rem;
  border: none;
  background: rgba($color: #fff, $alpha: 0.4);

  &:focus {
    outline: none;
    border: none;
  }
}
