.turn-table {
  background: rgba($color: #fff, $alpha: 0.4);
  border-radius: 1rem;
  width: 100%;
  height: 50vh;

  table {
    width: 100%;

    td {
      font-size: 1.2rem;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      padding-top: 0.75rem;
      line-height: 1;

      &:nth-of-type(1) {
        color: #fff;
      }

      &:nth-of-type(2) {
        color: #fff;
      }

      &:nth-of-type(3) {
        text-align: center;
        padding: 0.25rem;
        color: #fff;
        background: rgba($color: #ff6a6a, $alpha: 0.66);
      }

      &:nth-of-type(4) {
        text-align: center;
        padding: 0.25rem;
        color: #fff;
        background: rgba($color: #073f74, $alpha: 0.66);
      }
    }
  }

  @media screen and (max-width: 992px) and (orientation: portrait) {
    max-width: 100%;
    width: 100%;
    height: 100%;
    padding: 1rem;
    table {
      td {
        line-height: 0.1;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;

        &:nth-of-type(1) {
          color: #073f74;
          padding: 1rem;
        }

        &:nth-of-type(2) {
          color: #073f74;
          padding: 1rem;
        }
      }
    }
  }
}
