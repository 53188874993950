.promotion-modal {
  .content {
    z-index: 5;
    display: flex;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    border-radius: 0.5rem;

    .promotion-item {
      cursor: pointer;
      padding: 1rem;
      border: 1px solid #073f74;
      margin: 1rem 0.75rem;
    }
  }
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 4;
  background-color: rgba($color: #000000, $alpha: 0.8);
}
