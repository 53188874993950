.landscape-warning {
  position: fixed;
  width: 100% !important;
  height: 100% !important;
  background: #f0e9d0;
  display: flex;
  padding: 0 5rem;
  z-index: 999999;
  .content {
    > * {
      margin-bottom: 1rem;
    }

    img {
      height: 6rem;
    }
    span {
      display: block;
      font-size: 2rem;
      color: #1d4279;
      font-weight: 600;
    }
  }

  .display {
    img {
      transform: translateY(1rem);
      width: 40rem;
    }
  }
}
