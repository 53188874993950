.score-card {
  background: rgba($color: #fff, $alpha: 0.4);
  border-radius: 1rem;
  font-size: 1.2rem;

  table {
    width: 100%;

    td {
      &.heading {
        color: #fff;
        text-transform: capitalize;
        font-size: inherit;
        font-weight: 600;
      }

      color: #fff;
      font-size: 1.2rem;
      text-align: center;
    }
  }

  @media screen and (max-width: 992px) and (orientation: portrait) {
    width: 100%;
    padding: 1rem;
    font-size: 1.6rem;
    table {
      td {
        &.heading {
          color: #073f74;
        }

        color: #073f74;
      }
    }
  }
}
