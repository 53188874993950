.stats-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  height: 80vh;
  width: 100%;
  transform: translateY(100%);
  z-index: 8;
  transition: all 0.3s ease-in;
  background: #f0e9d0;

  &.open {
    transform: translateX(0);
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 2rem;
    & > div:last-of-type {
      flex: auto;
    }

    .close {
      cursor: pointer;
      background: rgba($color: #fff, $alpha: 0.4);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 0.5rem;
      position: absolute;
      right: 2rem;
      top: 1rem;

      svg {
        font-size: 1.5rem;
      }
    }
  }

  .control-bar {
    height: 6rem;
    display: flex;
    justify-content: space-between;
  }

  .title {
    color: #073f74;
    font-size: 2.2rem;
    font-style: italic;
    font-weight: 600;
  }
  // ---------------------------------------------------------

  .language-active {
    color: #073f74;
    font-weight: 900;
  }
}
