.navbar-container {
  background-color: #1d4279;
  height: 4rem;

  @media screen and (max-width: 480px) {
    display: block;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    display: block;
    height: 6rem;
  }

  .logo img {
    width: 2.3rem;
    @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      width: 3em;
    }
  }

  .content {
    height: 100%;
    padding: 0 2rem;
    margin-left: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .navbar-action {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      background: #cf5060;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        width: 4rem;
        height: 4rem;
      }
    }
  }
}
