.captured-pieces-container {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;

  @media screen and (max-width: 480px) {
    display: flex;
    flex: auto;
  }

  .captured-piece {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f0e9d0;
    border-radius: 50%;
    padding: 0.3rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;

    img {
      transform: translateY(-12%);
      height: 80%;
    }

    @media screen and (max-width: 480px) {
      width: 2rem;
      height: 2rem;
    }

    /* iPads (landscape) ----------- */
    @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      width: 2rem;
      height: 2rem;
    }

    /* iPads (portrait) ----------- */
    @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      width: 3.5rem;
      height: 3.5rem;
    }

    /* Desktops and laptops ----------- */
    @media screen and (min-width: 1024px) {
      width: 4rem;
      height: 4rem;
      margin-right: 1rem;
      margin-bottom: 1rem;
    }

    /* Large screens ----------- */
    @media screen and (min-width: 1824px) {
      width: 5rem;
      height: 5rem;
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
  }
}
