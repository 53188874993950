@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");

*,
*::after,
*::before {
  font-family: "Open Sans", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.25%;
}

.app {
  overflow: hidden;
  background-size: cover;
  background: url("./assets/game-bg.svg") no-repeat 25% 30%;
}

.t-orange {
  color: #fa958c;
}
