.hamburger-menu {
  display: flex;
  flex-direction: column;
  background: #1d4279;
  height: 100%;
  text-align: left;
  padding: 3rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: translate(-100%);
  min-width: 300px;
  z-index: 10;

  &.open {
    transform: translate(0px);
  }

  .item {
    margin-bottom: 1.5rem;
  }

  .content {
    margin-top: 4rem;
  }

  .actions {
    margin-top: auto;
  }

  .game-over-message-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center
  }

  .game-over-msg {
    font-size: 3rem;
    color: white;
  }
}

