.game-info {
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  padding: 0.2rem 1rem;
  display: block;
  width: 100%;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    opacity: 0.3;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .content {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div:nth-of-type(1) {
      width: 10rem;
      overflow: hidden;
    }
  }

  .name {
    font-weight: 600;
    font-size: 1.8rem;
    text-transform: capitalize;
    color: #f0e7cf;
  }

  .labeler {
    height: 5rem;
    margin-right: auto;
    margin-left: 1rem;
  }

  .captured-pieces-container {
    max-width: 50rem;
  }

  .timer {
    font-size: 1.4rem;
    color: #fff;
    font-weight: 600;
  }
}